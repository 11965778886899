<template>
  <div class="goodsActivity">
    <!-- <el-button @click="getList" type="primary">主要按钮</el-button> -->
    <slideshow :slideshowList="slideshowList"></slideshow>
    <img class="debris" src="~static/debris.png" />
    <img
      class="Aboutbg"
      style="top: 11%; right: 0"
      src="~static/crane.png"
    />
    <img
      class="Aboutbg"
      style="top: 35%; right: 0"
      src="~static/crane.png"
    />
    <img
      class="Aboutbg"
      style="bottom: 18%; right: 0"
      src="~static/crane.png"
    />
    <img
      class="Aboutbg"
      style="top: 28%; left: 0"
      src="~static/wasteL.png"
    />
    <img
      class="Aboutbg"
      style="top: 56%; left: 0"
      src="~static/wasteL.png"
    />
    <img
      class="Aboutbg"
      style="bottom: 0; left: 0"
      src="~static/wasteL.png"
    />
    <img
      class="Aboutbg"
      style="top: 16%; right: 0"
      src="~static/wasteR.png"
    />
    <img
      class="Aboutbg"
      style="top: 53%; right: 0"
      src="~static/wasteR.png"
    />
    <img
      class="Aboutbg"
      style="bottom: 3%; right: 0"
      src="~static/wasteR.png"
    />
    <el-container>
      <el-main>
        <div>
          <div id="general" class="general">
            <div style="">
              <!-- <hr /> -->
              <div class="top-title">
                <img class="home-icon" src="../../../static/scenery/home.png" alt="" style="width:20;height:20;">
                <span style="margin-right:6px;font-weight:bold" @click="Jump('/',0)">首页</span>
                >
                <span style="margin-left:6px;" @click="Jump('/goodsActivity')">玩转条子泥</span>
                >
                <span style="margin-left:6px;color:#509AB1">精品活动</span>
                <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item :to="{ path: '/' }" @click.native="$store.commit('active',0)">
                    <img
                      class="home-icon"
                      src="~static/scenery/home.png"
                      alt=""
                      style="width: 20; height: 20"
                    />
                    首页</el-breadcrumb-item
                  >
                  <el-breadcrumb-item>玩转条子泥</el-breadcrumb-item>
                  <el-breadcrumb-item>精品活动</el-breadcrumb-item>
                </el-breadcrumb> -->
              </div>
              <div class="hr-more">
                <div>
                  <!-- <img
                    style="transform: rotateY(180deg);"
                    width="43px"
                    src="~static/hrimg.png"
                  /> -->
                  精品活动
                  <!-- <img width="43px" src="~static/hrimg.png" />
                  <br />
                  <span>DONGTAI TIAOZINI</span> -->
                </div>
              </div>
              <div class="Hrbg2">聚焦条子泥·摄影欢乐会</div>
              <div class="tabs">
                <div
                  @click="tabsListApi(2,'402880447baab07f017baac4d5fc00bb')"
                  :class="activeNum ? 'tabsB' : ''"
                >
                  往期作品
                </div>
                <div
                  @click="tabsListApi(1,'402880447baab07f017baac4d5fc00bb')"
                  :class="activeNum ? '' : 'tabsB'"
                >
                  现场采风
                </div>
              </div>
              <div class="imgBox">
                <div
                  v-for="item in tabs01List"
                  :key="item.id"
                  style="margin: 10px 15px;"
                >
                  <img
                    style="position: absolute;z-index: 1;"
                    src="~static/tabsbg01.png"
                  />
                  <el-image style="width: 380px;height: 350px;border-radius: 50%;" fit="cover" :src="item.picUrl" lazy></el-image>
                </div>
              </div>
              <router-link class="morebg"  :to="{path:'/viewMore',query: {name: '聚焦条子泥·摄影欢乐会',id:'402880447baab07f017baac4d5fc00bb'}}">查看更多</router-link>
            </div>
          </div>
          <div
            id="introduce"
            class="introduce"
          >
            <div class="Hrbg2">滩玩儿</div>
            <div v-if="false" class="tabs">
              <div
                @click="tabsListApi(2,'402880447baab07f017baac511bd00c0')"
                :class="activeNum2 ? 'tabsB' : ''"
              >
                往期作品
              </div>
              <div
                @click="tabsListApi(1,'402880447baab07f017baac511bd00c0')"
                :class="activeNum2 ? '' : 'tabsB'"
              >
                现场采风
              </div>
            </div>
            <div class="imgBox">
              <div
                v-for="item in tabs02List"
                :key="item.id"
                style="margin: 10px 15px;"
              >
                <img
                  style="position: absolute;z-index: 1;"
                  src="~static/tabsbg02.png"
                />
                <el-image style="width: 380px;height: 240px;" fit="cover" :src="item.picUrl" lazy></el-image>
              </div>
            </div>
            <router-link class="morebg" :to="{path:'/viewMore',query: {name: '滩玩儿',id:'402880447baab07f017baac511bd00c0'}}">查看更多</router-link>
          </div>
          <div
            id="greatDeeds"
            class="greatDeeds"
            style="padding: 120px 80px 0 80px;"
          >
            <div class="Hrbg2">英语研学科普</div>
            <div class="imgBox">
              <div
                v-for="item in tabs03List"
                :key="item.id"
                style="margin: 10px 15px;"
              >
                <img
                  style="position: absolute;z-index: 1;"
                  src="~static/tabsbg03.png"
                />
                <el-image style="width: 340px;height: 340px;border-radius: 50%;" fit="cover" :src="item.picUrl" lazy></el-image>
              </div>
            </div>
            <router-link class="morebg" :to="{path:'/viewMore',query: {name: '英语研学科普',id:'402880447baab07f017baac5931200c6'}}">查看更多</router-link>
          </div>
          <div style="padding: 120px 80px 0 80px;">
            <div class="Hrbg2">寻找勺嘴鹬</div>
            <div class="imgBox">
              <div
                v-for="item in tabs05List"
                :key="item.id"
                style="margin: 10px 15px;"
              >
                <img
                  style="position: absolute;z-index: 1;opacity: .8;"
                  src="~static/tabsbg02.png"
                />
                <el-image style="width: 380px;height: 240px;" fit="cover" :src="item.picUrl" lazy></el-image>
              </div>
            </div>
            <!-- <div class="imgBox">
              <div
                v-for="item in tabs05List"
                :key="item.img"
                style="margin: 10px 15px;"
              >
                <img
                  style="position: absolute;z-index: 1;opacity: .8;"
                  src="~static/tabsbg04.png"
                />
                <el-image style="width: 350px;height: 350px;" fit="cover" :src="item.picUrl" lazy></el-image>
              </div>
            </div> -->
            <router-link class="morebg" :to="{path:'/viewMore',query: {name: '寻找勺嘴鹬',id:'402880447c7ea080017c8297c09f7b27'}}">查看更多</router-link>
          </div>

          <div style="padding: 120px 80px 0 80px;">
            <div class="Hrbg2">铁人三项</div>
            <div class="imgBox">
              <div
                v-for="item in tabs04List"
                :key="item.id"
                style="margin: 10px 15px;"
              >
                <img
                  style="position: absolute;z-index: 1;"
                  src="~static/tabsbg03.png"
                />
                <el-image style="width: 340px;height: 340px;border-radius: 50%;" fit="cover" :src="item.picUrl" lazy></el-image>
              </div>
            </div>
            <router-link class="morebg" :to="{path:'/viewMore',query: {name: '铁人三项',id:'402880447baab07f017baac5d7dc00cf'}}">查看更多</router-link>
          </div>

          <div
            id="geography"
            class="geography"
          >
            <div class="hr-more">其他活动</div>
            <div class="PastPhotos">
              <div style="height:220px"></div>
              <div class="swiper-container" style="transform: scale(.8)">
                <div class="swiper-wrapper">
                  <div class="swiper-slide" v-for="(item, index) in tabs06List" :key="index">
                    <a :style="{display: 'block',width: '310px',background:'url(' + item.picUrl + ') no-repeat','background-size':'cover', height: '310px'}" href="#"><img src="~static/PastPhotosbgS.png" width="310" alt=""></a>
                  </div>
                </div>
              </div>
              <router-link style="position: absolute;
                  bottom: 15px;
                  left: 50%;
                  transform: translateX(-50%);" class="morebg" :to="{path:'/viewMore',query: {name: '其他活动',id:'402880447bcd46cd017bd4ce0f6d4ca6'}}">查看更多</router-link>
            </div>
          </div>
        </div>
        <leftFloat :floatList="floatList" />
        <div ref="backTopBG" style="position: fixed;
            display: none;
            right: 56px;
            color: #509AB1;
            font-weight: bold;
            text-align: center;
            bottom: 130px;
            z-index: 9;" @click="backTop()">
          <img src="~static/backTopbg.png" alt="">
          <p>回到顶部</p>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import * as api from "@/api/test/test";
import Swiper from "swiper/swiper-bundle.min.js";
import "swiper/swiper-bundle.min.css";

export default {
  name: "About",
  data() {
    return {
       floatList: {
        list: [
          { name: "精品活动", url: "flush" },
          { name: "全年活动", url: "/allYearActivity" },
        ],
        title: "玩转条子泥",
      },
      mySwiper: null,
      slideshowList: ``,
      activeNum: true,
      activeNum2: true,
      tabs01List: [],
      tabs02List: [],
      tabs03List: [],
      tabs04List: [],
      tabs05List: [],
      tabs06List:[],
    };
  },
  methods: {
    tabsListApi(e = 1, activeId){
      api.layUIPage({
        isUse : "1" , activeId, picType : e , "size" : 6 , "page" : 0
      }).then(({data}) => {
        switch (activeId) {
          case '402880447baab07f017baac4d5fc00bb': // 摄影欢乐会
            this.tabs01List = Object.freeze(data) 
            e == 1 ?  this.activeNum = true : this.activeNum = false
            break;
          case '402880447baab07f017baac511bd00c0': //滩玩儿
            this.tabs02List =  Object.freeze(data) 
            e == 1 ?  this.activeNum2 = true : this.activeNum2 = false
            break;
          case '402880447baab07f017baac5931200c6': // 英语研学科普
            this.tabs03List =  Object.freeze(data) 
            break;
          case '402880447baab07f017baac5d7dc00cf':// 铁人三项
            this.tabs04List =  Object.freeze(data) 
            break;
          case '402880447c7ea080017c8297c09f7b27':// 寻找勺嘴鹬
            this.tabs05List =  Object.freeze(data) 
            break;
          case '402880447bcd46cd017bd4ce0f6d4ca6': //其他活动
            this.tabs06List =  data
            this.$nextTick(() => {
            this.mySwiper = new Swiper(".swiper-container", {
                direction: "horizontal",
                initialSlide: 1,
                slidesPerView: "4",
                centeredSlides: true,
                spaceBetween: 10,
                autoplay: {
                        disableOnInteraction: false, //手动滑动之后不打断播放
                        delay: 1000
                      },
                loop: true,  //循环
                observer:true,
                observeParents:true
              });
            });
            break;
          default:
            break;
        }
      });
    },
    backTop() {
      const that = this;
      let ispeed = Math.floor(that.scrollTop / 5);
      document.documentElement.scrollTop = document.body.scrollTop =
        that.scrollTop + ispeed;
      // if (that.scrollTop === 0) {
      //   clearInterval(timer);
      // }
    },
  },
  created() {
    },
  mounted() {
    api.slideshow().then((res) => {
      this.slideshowList = res;
    });
    this.tabsListApi(2,'402880447baab07f017baac4d5fc00bb')// 摄影欢乐会
    this.tabsListApi(0,'402880447baab07f017baac511bd00c0') //滩玩儿
    this.tabsListApi(0,'402880447baab07f017baac5931200c6')// 英语研学科普
    this.tabsListApi(0,'402880447baab07f017baac5d7dc00cf')// 铁人三项
    this.tabsListApi(0,'402880447c7ea080017c8297c09f7b27')// 寻找勺嘴鹬
    this.tabsListApi(0,'402880447bcd46cd017bd4ce0f6d4ca6') //其他活动
  },
  destroyed() {
  },
};
</script>
<style scoped>
.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
     background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
     background-color: #d3dce6;
  }
.PastPhotos{
  background: url(~static/PastPhotosbg.png) no-repeat;
  height: 730px;
}
.imgBox{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  transform: scale(.8);
  width: 1400px;
  margin: 0 auto;
}
>>> .el-breadcrumb {
  line-height: 92px;
  font-size: 24px;
}
.top-title {
  background-size: cover;
  background-image: url(/static/img/title.ab49d158.png);
  background-repeat: no-repeat;
  letter-spacing: 5px;
  width: 1200px;
  margin: 0 auto;
  text-align: left;
  line-height: 92px;
  font-size: 24px;
}
.home-icon {
  width: 20px;
  height: 20px;
  margin: 0 16px 0 53px;
}
.top-title>span:hover{
    cursor: pointer;
    color: #509AB1;
}
.morebg {
  display: block;
  width: 201px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  color: #509ab1;
  margin: 0 auto;
  background: url("~static/morebg.png") no-repeat;
}
.tabs {
  display: flex;
  justify-content: center;
}
.tabs > div {
  width: 230px;
  height: 59px;
  line-height: 59px;
  text-align: center;
  margin: 15px;
  font-size: 23px;
  color: #509ab1;
  background: url("~static/tab.png") no-repeat;
}
.tabsB {
  background: url("~static/tabAcitve.png") no-repeat !important;
  background-size: cover !important;
  color: #f6d397 !important;
}
.Hrbg2 {
  width: 440px;
  height: 85px;
  line-height: 85px;
  text-align: center;
  margin: 0 auto;
  font-size: 28px;
  font-family: PangMenZhengDao-Cu;
  font-weight: 400;
  color: #f6d397;
  background: url("~static/Hrbg2.png") no-repeat;
}
.Aboutbg {
  position: absolute;
}
.introduce_tabs {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
}
.introduce_tabs p,
.textSpan {
  font-size: 25px;
  font-family: PangMenZhengDao-Cu;
  font-weight: bold;
  color: #509ab1;
}
.debris {
  width: 100%;
  margin-top: -157px;
  z-index: 8;
  background: transparent;
  position: relative;
}
.active {
  background-color: #509ab1;
}
.greatDeeds_r h3 {
  width: 55px;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #509ab1;
  line-height: 41px;
}
.greatDeeds_r h5 {
  width: 357px;
  height: 18px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
}
.greatDeeds_r > div {
  position: absolute;
  left: -50vw;
  width: 100vw;
  display: flex;
  justify-content: space-evenly;
}
.greatDeeds_bzt {
  height: 100%;
  position: relative;
  left: 50%;
  width: 2px;
  border: none;
  background: darkgray;
}
.greatDeeds_bzt > h2 {
  position: absolute;
  right: -19px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #509ab1;
  border-radius: 25px;
  text-align: center;
}
img[class^="general"] {
  position: absolute;
  z-index: -99;
}
.Hcontent {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 160px;
  background: url("~static/img3bg.png") no-repeat 100% 100%;
  background-size: cover;
  height: 600px;
}
.content-text {
  width: 142px;
  height: 231px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: url("~static/img13.png") no-repeat 100% 100%;
}
.content-text > p {
  width: 30px;
  font-size: 21px;
  font-weight: bold;
  line-height: 29px;
  color: #317981;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* 静态资源 */
.content-grid {
  width: 220px;
  height: 320px;
  z-index: 12;
  position: relative;
}

.introduceText {
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-line-clamp: 11;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 10px;
}
.introduce {
  background-size: cover;
}
.el-tabs__content > div {
  display: flex;
}
.greatDeeds_2 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.greatDeeds_2 > div {
  padding-left: 40px;
  background-position-y: 50%;
}
.icon1 {
  background: url("~static/msg.png") no-repeat;
}
.icon2 {
  background: url("~static/site.png") no-repeat;
}
.icon3 {
  background: url("~static/time.png") no-repeat;
}
.icon4 {
  background: url("~static/icon4.png") no-repeat;
}

>>> .el-tabs__content {
  height: 300px;
}
.hr-more {
  white-space: nowrap;
  letter-spacing: 3px;
  line-height: 250px;
  width: 452px;
  height: 250px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  top: -25px;
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
  background: url("~static/Hrbg.png");
  font-size: 35px;
  font-family: PangMenZhengDao-Cu;
  font-weight: 400;
  color: #509ab1;
  text-shadow: ;
}
.hr-more img {
  margin: 0 6px;
}
.hr-more span {
  width: 119px;
  height: 9px;
  font-size: 12px;
  font-family: Arial;
  font-weight: 400;
  color: #b3b3b3;
  line-height: 24px;
}
.goodsActivity {
  position: relative;
}
.general {
  /* display: flex; */
  /* box-shadow: 0px -7px 18px 0px rgb(0 0 0 / 10%); */
  /* padding-left: 20px; */
  margin-bottom: 50px;
  /* background: url("~static/generalbg.png") no-repeat -6% -49%; */
}
.general p {
  /* text-indent: 28px; */
  height: 339px;
  writing-mode: tb-rl;
  letter-spacing: 12px;
}
.generalImg {
  flex: 1;
}
.el-main {
  padding: 0;
  overflow-x: hidden;
}
</style>